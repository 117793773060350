export const pageSettingsTaloja = {
  landingPage: {
    title:
      "Lodha World School - SSC School in Taloja | Safe & Lifelong Learning",
    metaTags: [
      {
        name: "description",
        content:
          "At Lodha World School, Taloja we empower your child with the opportunity to learn and grow in a safe and uplifting environment Join us in shaping a bright future. We are affiliated to SSC board.",
      },
      {
        property: "og:title",
        content:
          "Lodha World School - SSC School in Taloja | Safe & Lifelong Learning",
      },
      { property: "og:site_name", content: "lodhaworldschooltaloja" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/taloja",
      },
      {
        property: "og:description",
        content:
          "At Lodha World School, Taloja we empower your child with the opportunity to learn and grow in a safe and uplifting environment Join us in shaping a bright future. We are affiliated to SSC board.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content:
          "https://www.lodhaworldschool.com/assets/chairpersonTaloja.jpg",
      },
    ],
    canonical: "https://www.lodhaworldschool.com/taloja",
  },
  ourStory: {
    title: "Explore Lodha World School's Remarkable Journey since 2008",
    metaTags: [
      {
        name: "description",
        content:
          "Join us in celebrating our incredible journey since 2008 where Lodha World School has flourished into a vibrant community with 4 campuses & affiliations with ICSE, CBSE, SSC, and ISC boards.",
      },
      {
        property: "og:title",
        content: "Explore Lodha World School's Remarkable Journey since 2008",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      { property: "og:url", content: "https://www.lodhaworldschool.com/" },
      {
        property: "og:description",
        content:
          "Join us in celebrating our incredible journey since 2008 where Lodha World School has flourished into a vibrant community with 4 campuses & affiliations with ICSE, CBSE, SSC, and ISC boards.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/chairperson.jpg",
      },
    ],
  },
  vision: {
    title: "Lodha World School - Vision & Mission",
    metaTags: [
      {
        name: "description",
        content:
          "Discover the vision and mission of Lodha World School (LWS), dedicated to nurturing individuals in body, mind, and spirit, and empowering them to become the Leaders of Tomorrow. Join Us!",
      },
      {
        property: "og:title",
        content: "Lodha World School - Vision & Mission",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/inspireuscurriculum",
      },
      {
        property: "og:description",
        content:
          "Discover the vision and mission of Lodha World School (LWS), dedicated to nurturing individuals in body, mind, and spirit, and empowering them to become the Leaders of Tomorrow. Join Us! ",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  values: {
    title:
      "Lodha World School Core Values: Supporting Leaders with Eternal Virtues",
    metaTags: [
      {
        name: "description",
        content:
          "Discover the core values at Lodha World School and how they are instilled through our four houses, each rooted in Sanskrit with timeless significance. ",
      },
      {
        property: "og:title",
        content:
          "Lodha World School Core Values: Supporting Leaders with Eternal Virtues",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/values",
      },
      {
        property: "og:description",
        content:
          "Discover the core values at Lodha World School and how they are instilled through our four houses, each rooted in Sanskrit with timeless significance. ",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  people: {
    title: "Meet the Leaders Behind Lodha World School's Vision",
    metaTags: [
      {
        name: "description",
        content:
          "Explore the dedicated individuals shaping the future of Lodha World School. From our Chairperson to esteemed members of the School Managing Committee & principals across our campuses.",
      },
      {
        property: "og:title",
        content: "Meet the Leaders Behind Lodha World School's Vision",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/people",
      },
      {
        property: "og:description",
        content:
          "Explore the dedicated individuals shaping the future of Lodha World School. From our Chairperson to esteemed members of the School Managing Committee & principals across our campuses.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  inspireUsCurriculum: {
    title: "Lodha World School Custom Curriculum - Inspire Us",
    metaTags: [
      {
        name: "description",
        content:
          "Discover 'Inspire Us' by Lodha World School, a globally researched custom curriculum, specially designed to deliver the academic rigour and excellence we aspire to achieve.",
      },
      {
        property: "og:title",
        content: "Lodha World School Custom Curriculum - Inspire Us",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/vision",
      },
      {
        property: "og:description",
        content:
          "Discover 'Inspire Us' by Lodha World School, a globally researched custom curriculum is specially designed to deliver the academic rigour and excellence we aspire to achieve.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  results: {
    title: "Lodha World Schools - Results",
    metaTags: [
      {
        name: "description",
        content:
          "Explore the results of the schools of Lodha World School. Witness the fruits of collaborative efforts among our leaders, staff, parents, and students, as we share the remarkable journeys.",
      },
      {
        property: "og:title",
        content: "Lodha World Schools - Results",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/results",
      },
      {
        property: "og:description",
        content:
          "Explore the results of the schools of Lodha World School. Witness the fruits of collaborative efforts among our leaders, staff, parents, and students, as we share the remarkable journeys.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  skillDevelopment: {
    title: "Lodha World School - Skill Development Beyond the Classroom",
    metaTags: [
      {
        name: "description",
        content:
          "At Lodha World School, education goes beyond classroom boundaries. Discover how Skill Development play a pivotal role in nurturing all-round leaders.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Skill Development Beyond the Classroom",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/skilldevelopent",
      },
      {
        property: "og:description",
        content:
          "At Lodha World School, education goes beyond classroom boundaries. Discover how Skill Development play a pivotal role in nurturing all-round leaders. ",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  leadershipDevelopment: {
    title:
      "Lodha World Schools - Leadership Development | Empowering Tomorrow's Leaders",
    metaTags: [
      {
        name: "description",
        content:
          "At LWS, education extends beyond traditional classrooms. We aim to mould & nurture all-round leaders, facilitating their development through our range of co-curricular activities & opportunities.",
      },
      {
        property: "og:title",
        content:
          "Lodha World Schools - Leadership Development | Empowering Tomorrow's Leaders",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/leadershipdevelopment",
      },
      {
        property: "og:description",
        content:
          "At LWS, education extends beyond traditional classrooms. We aim to mould & nurture all-round leaders, facilitating their development through our range of co-curricular activities & opportunities.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  awards: {
    title: "Lodha World School - Awards and Achievements",
    metaTags: [
      {
        name: "description",
        content:
          "Lodha World School garnered recognition and awards that set them apart in the field of education. From prestigious Grand Jury Awards to Reliance Awards, explore their outstanding achievements.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Awards and Achievements",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/awards",
      },
      {
        property: "og:description",
        content:
          "Lodha World School garnered recognition and awards that set them apart in the field of education. From prestigious Grand Jury Awards to Reliance Awards, explore their outstanding achievements.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  testimonials: {
    title: "Lodha World School - Testimonials From Parents & Students",
    metaTags: [
      {
        name: "description",
        content:
          "Discover what parents & students has to say about the school's dedication to fostering well-rounded personalities through a balanced focus on academics and extracurricular activities.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Testimonials From Parents & Students",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/testimonials",
      },
      {
        property: "og:description",
        content:
          "Discover what parents & students has to say about the school's dedication to fostering well-rounded personalities through a balanced focus on academics and extracurricular activities.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  staff: {
    title:
      "Lodha World School - Career Opportunities & Benefits | Join Our Team",
    metaTags: [
      {
        name: "description",
        content:
          "At LWS, our staff members are lifelong learners, equipped with professional credentials and additional training from renowned institutions. Check LWS career opportunities here.",
      },
      {
        property: "og:title",
        content:
          "Lodha World School - Career Opportunities & Benefits | Join Our Team",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/career",
      },
      {
        property: "og:description",
        content:
          "At LWS, our staff members are lifelong learners, equipped with professional credentials and additional training from renowned institutions. Check LWS career opportunities here.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  alumni: {
    title: "Lodha World School - Alumni: Forever Part Of Our Community",
    metaTags: [
      {
        name: "description",
        content:
          "At Lodha World School, our alumni are a source of immense pride. Witness the transformation of our former students into the leaders they were destined to become.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Alumni: Forever Part Of Our Community",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/alumni",
      },
      {
        property: "og:description",
        content:
          "At Lodha World School, our alumni are a source of immense pride. Witness the transformation of our former students into the leaders they were destined to become.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  lwsMarkers: {
    title: "Lodha World School - Markers",
    metaTags: [
      {
        name: "description",
        content:
          "At Lodha World School, the pupil is at the heart of everything we do. We go the extra mile to mould these young leaders in revolutionary ways.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Markers",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/lwsmarkers",
      },
      {
        property: "og:description",
        content:
          "At Lodha World School, the pupil is at the heart of everything we do. We go the extra mile to mould these young leaders in revolutionary ways.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
};
export const lightBgWebTaloja = {
  imageSource: "/assets/bg8.gif",
};
export const sidebarTaloja = {
  fb: "https://www.facebook.com/lodhaworldschoolmumbai/",
  linkedin: "https://www.linkedin.com/company/lodha-world-school/",
  insta: "https://instagram.com/lodhaworldschool?igshid=YmMyMTA2M2Y=",
  email: "mailto:contact_taloja@lodhaworldschool.com",
  admission: "mailto:admission_taloja@lodhaworldschool.com",
  emailname: "contact_taloja@lodhaworldschool.com",
  admissionname: "admission_taloja@lodhaworldschool.com",
  phone: "tel:93216-83402",
  phonename: "+91 93216 83402",
};
export const sidebarlinkTaloja = [
  {
    logo: "/assets/board3.png",
    link: "https://www.mahahsscboard.in/",
    width: "80px",
  },
];

export const websiteSchemaTaloja = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Lodha World School, Taloja",
  url: "https://www.lodhaworldschool.com/taloja",
  logo: "https://www.lodhaworldschool.com/assets/logo.svg",
};
export const personSchemaTaloja = {
  "@context": "http://schema.org",
  "@type": "WebPage",
  accountablePerson: [
    {
      "@type": "Person",
      name: "Ms. Ajita Gupta",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people7.jpg",
      jobTitle: "Principal",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, Taloja",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Seema Nair",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people17.png",
      jobTitle: "School Coordinator - Primary",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, Taloja",
      },
    },
  ],
};

export const lightBgMobileTaloja = {
  imageSource: "/assets/bg8mobile.gif",
};

export const peopleTaloja = [
  {
    imageSource: "/assets/people7.jpg",
    name: "Ms. Ajita Gupta",
    title: "Principal",
  },
  {
    imageSource: "/assets/people17.png",
    name: "Ms. Seema Nair",
    title: "School Coordinator - Primary",
  },
];
export const testimonialsTaloja = [
  {
    name1: "Ms.Trupti Tate",
    name2: "Parent of Vidhi Tate",
    title:
      "After joining Lodha World School Taloja, my daughter actively participates in extracurricular activities apart from studies. She has become more confident enough to perform in public.",
  },
  {
    name1: "Mr. Laxman Shanbag",
    name2: "",
    title:
      "I would like to take this opportunity to thank Lodha World School Team Members [Principal Madam to Ground Staff] for Nurturing my Ward’s… I really appreciate school team efforts for providing quality education [during ONLINE & now OFFLINE] & moulding them towards the path which surely will Lead to Success. It’s a Privilege, to get Education in Lodha World School. Thanks for Planting the Seeds which will be Grown Up to 'LEADERS OF TOMORROW'",
  },
  {
    name1: "Ms. Anjali Arora",
    name2: "Parent of Nidhika Kaur Arora",
    title:
      "My experience with Lodha World School Taloja is being awesome, as my daughter, Nidhika Kaur Arora, was transfer case from other school to this one, I was bit worried about how she will cope up with the new school curriculum and whether she will take interest and active participation but trust me it just went in smooth way. Her first day of school she got so much comfortable with the school ambience, teaching faculty and every one, she was so happy and within a short span of time she has improved in reading, writing and presentation skills. The whole team from Principal to teachers and non-teaching staff is striving hard for the betterment and overall personality of our child. This is the best state board school in the locality with reputed name of Lodha World chain of Schools.",
  },
];
export const resultsTaloja = [];
export const homeAwardsTaloja = {
  imageSource: "/assets/starawards.png",
  id: "awardsTaloja",
};

export const starAwardsTaloja = [
  {
    imageSource: "/assets/awards.png",
    name: "Grand Jury Award - Design Thinking Leaders, EducationWorld India Rankings 2023-24",
  },
  {
    imageSource: "/assets/awards.png",
    name: "Outstanding Performance in Sports Facilities, Free Press Journal Survey 2023",
  },
  {
    imageSource: "/assets/awards.png",
    name: "Outstanding Extra-curricular Performance, Free Press Journal Survey 2023",
  },
];
export const chairpersonMsgTaloja = {
  imageSource: "/assets/chairpersonTaloja.jpg",
  alt: "Lodha World School, Taloja - Principal's Message",
  imgTitle: "Principal's Message about Lodha World School, Taloja",
  name: "Ms. Ajita Gupta",
  title:
    "Every child deserves the opportunity to learn in an environment that is safe and uplifting and teaches skills for life-long learning.",
  nameMain: "-Ms. Ajita Gupta",
  cta: "Principal’s Message",
  dialogText1:
    "School leaders must adopt a vision that they believe encompasses the best teaching practices and most valuable learning goals. My role as an educator is to guide the talents and energies of teachers and students.",
  dialogText2:
    "I will strive to provide a vision acquired through personal inquiry and research, collaboration and goal setting as a team member and leader. It is important to foster collaborative efforts internally with my staff members and externally with parents.",
  dialogText3:
    "My personal philosophy in education is rooted in the belief that every child deserves the opportunity to learn in an environment that is safe and uplifting with overall determination to teach and model skills of life-long learning.",
  dialogText4:
    "There are many concepts that can define what is to be a leader in the world of education today, with the ever growing need to advance the level of achievement in education and the increasing emphasis on accountability for educators, a large pool of individuals are being empowered with the task of leadership in their own school.",
};

export const middleYearsTaloja = [
  {
    imageSource: "/assets/inspireUsImg.png",
    alt: "Foundation & Middle Years - Lodha World School, Taloja",
    imgTitle: "Foundation & Middle Years at Lodha World School, Taloja",
    name: "Foundation & Middle Years",
    title:
      "At LWS, we redefine conventional learning. Our globally researched curriculum is crafted to deliver academic excellence.",
    cta: "Inspire Us Curriculum",
  },
];
export const seniorYearsTaloja = {
  imageSource: "/assets/academicsImg.png",
  alt: "Senior Years - Lodha World School, Taloja",
  imgTitle: "Senior Years at Lodha World School, Taloja",
  name: "Senior Years",
  title:
    "We are affiliated to the State Board (SSC). We train our young leaders in accordance with its rigorous standards.",
  cta: "SSC Curriculum",
};

export const seniorpara1Taloja = {
  para1:
    "At LWS Taloja, we uncover areas for improvement of every pupil through cyclical tests. These tests help us identify gaps and what causes them, providing a focus point for the teachers. Apart from academics we also focus on sports, art and craft that drive productivity and the skills of creative imagination among all our pupils.",
  para2:
    "In all we do, we endeavour to stimulate curiosity in young, impressionable minds, equipping them with tools to become better human beings.",
  title1: "Best practices followed at LWS Taloja",
  title2: "Subjects offered - SSC Curriculum:",
  title3: "",
  para3:
    "The primary objective of the State Board is to ensure every student receives quality education. Some of the features and advantages of the SSC Curriculum are:",
};

export const seniorpara2Taloja = [
  {
    name: "Affordable education",
  },
  {
    name: "Self-paced learning",
  },
  {
    name: "Importance to non-academic activities",
  },
  {
    name: "Relevant subjects",
  },
  {
    name: "In-depth syllabus",
  },
  {
    name: "State-wise personalised syllabus",
  },
  {
    name: "Lenient grading system",
  },
];

export const seniorpara3Taloja = [
  {
    name: "English",
  },
  {
    name: "Marathi/ Hindi",
  },
  {
    name: "Mathematics",
  },
  {
    name: "Science",
  },
  {
    name: "Social Science",
  },
];

export const seniorpara4Taloja = [];

export const inspireUsTaloja = {
  title1: "Foundation &",
  title2: "Middle Years",
};
export const landingTaloja = {
  imageSource: "/assets/thane.jpg",
  title1: "Lodha World School, Taloja - ",
  title2: "Building the Leaders of Tomorrow",
};
export const academicsTaloja = {
  imageSource: "/assets/symbioticSlide5.jpg",
  title: "Class of 2023 Results",
  name: "The Class of 2023 Results are a testament to our relentless drive towards pursuing academic excellence.",
  cta: "CBSE Curriculum",
};
export const joinLwsTaloja = {
  title: "Join Lodha World School, Taloja",
  imageSource: "/assets/jointaloja.png",
};
export const ourstoryTaloja = [
  {
    name: "Our school represents not just another branch of the Lodha World School community, but also a new venture. In June 2019, Lodha World School set up its first SSC-affiliated campus at Taloja, with the aim of bringing education to the masses. Equipped with well-trained faculty and state-of-the-art infrastructure, our school endeavours to nurture the young minds in and around the neighbourhood, shaping them to be the future of the nation. The Taloja Community - across its learners, teachers, parents - works together to achieve the LWS motto of creating responsible and empathetic global citizens.",
  },
];
export const visionTaloja = [
  {
    title:
      "To nurture individuals sound of body, mind and spirit, and equip them to emerge as Leaders of Tomorrow.",
  },
];
export const valuesTaloja = [
  {
    id: "houses-1",
    title: "Atulya:",
    color: "#9C2020",
    subtitle: "the incomparable.",
    description:
      "A leader is incomparable, unequalled in their quest for excellence, and exhibits unmitigable zeal.",
    quote:
      "Inspiration: Shri JRD Tata - ethical and trailblazing business leadership & corporate social initiatives",
  },
  {
    id: "houses-2",
    title: "Advaita:",
    color: "#276440",
    subtitle: "the one and only one.",
    description: "A leader has an unchanging, singular vision of the goal.",
    quote:
      "Inspiration: Shri APJ Abdul Kalam - unique peerless vision for our country and society",
  },
  {
    id: "houses-3",
    title: "Ananta:",
    color: "#034667",
    subtitle: "the infinite.",
    description: "A leader embodies limitless possibilities and energy.",
    quote:
      "Inspiration: Mother Teresa - utmost compassion, extended to the most downtrodden",
  },
  {
    id: "houses-4",
    title: "Akshaya:",
    color: "#CF9832",
    subtitle: "the undiminishing, inexhaustible spirit.",
    description:
      "A leader exemplifies an undiminishing, inexhaustible spirit in all they set out to do.",
    quote:
      "Inspiration: Mahatma Gandhi - inexhaustible strength, tolerance, patience, determination to achieve freedom",
  },
];
export const skillachievementsTaloja = [
  {
    imageSource: "/assets/achieve10.png",
    name: "Fancy Dress Competition",
    title: "Gagandeep Sai - Jr. Kg.",
  },
  {
    imageSource: "/assets/achieve10.png",
    name: "Design Your Jewellery",
    title: "Alice Paul, Anaisha Vinekar, Gargi Gadhave - Class I",
  },
  {
    imageSource: "/assets/achieve2.png",
    name: "Street Play",
    title: "Class VI-VII",
  },
  {
    imageSource: "/assets/achieve2.png",
    name: "Solo Dance Competition",
    title: "Sreyash Padhy - Class IV",
  },
];
export const leadershipachievementsTaloja = [];
export const missionTaloja = [
  {
    id: "vslide-1",
    text: "To provide quality school education, progressive in challenging pupils to take advantage of the emerging global opportunities.",
  },
  {
    id: "vslide-2",
    text: "To nurture pupils to make them caring, competent and contributing members of society.",
  },
  {
    id: "vslide-3",
    text: "To establish pupil-centred environments for academics as well as extracurricular opportunities that nourish self-efficacy and self-esteem.",
  },
  {
    id: "vslide-4",
    text: "To stimulate critical thinking and commitment to excellence that gradually lead to success in higher education and career.",
  },
  {
    id: "vslide-5",
    text: "To create a school community that reflects and respects ethnic, racial, religious and national diversity.",
  },
];

export const inspireTaloja = [
  {
    id: "inspireUsThane-1",
    title: "Learner-centric",
    description:
      "Following the Montessori Methodology, our curriculum presupposes that each learner has a unique individual promise and unique abilities, thus encouraging personalised learning.",
  },
  {
    id: "inspireUsThane-2",
    title: "Inclusive",
    description:
      "The instructional approach and content, learning objectives, and pace of learning are optimised according to each learner’s needs, ensuring no learner is left behind.",
  },
  {
    id: "inspireUsThane-3",
    title: "Holistic",
    description:
      "Learning activities are meaningful and relevant to both the academic and personal lives of learners, making learning holistic.",
  },
  {
    id: "inspireUsThane-4",
    title: "Skill-based",
    description:
      "The curriculum is designed to build artistic creativity, design thinking, and innovation. It is also deeply entrenched in our Four Markers, which helps train and empower our learners.",
  },
];

export const skillTaloja = [
  {
    id: "skillTaloja-1",
    title: "Sports",
  },
  {
    id: "skillTaloja-2",
    title: "Dance",
  },
  {
    id: "skillTaloja-3",
    title: "Drama & Theatre",
  },
  {
    id: "skillTaloja-4",
    title: "Art",
  },
];
export const leadersTaloja = [
  {
    imageSource: "/assets/leadertaloja.png",
    section1: "",
    section2: "",
  },
];

export const captainTaloja = [];
export const headsTaloja = [];

export const prefectTaloja = [
  {
    position: "Head Boy: ",
    name: "Soham Kondvilkar",
  },
  {
    position: "Head Girl: ",
    name: "Lubna Shaikh",
  },
  {
    position: "Sports Captain: ",
    name: "Nidhika Arora",
  },
  {
    position: "Discipline Captain: ",
    name: "Riya Bodake",
  },
];

export const leadershipTaloja = [
  {
    id: "leadershipTaloja-1",
    title: "Community Outreach",
  },
  {
    id: "leadershipTaloja-2",
    title: "Student Council",
  },
  {
    id: "leadershipTaloja-3",
    title: "Competitions",
  },
];

export const eventsTaloja = [];

export const schoolTaloja = [
  {
    img: "/assets/taloja.jpg",
    name: "Taloja",
    email: "careers_taloja@lodhaworldschool.com",
    phone: "+91-93216-83402",
    cta: "Taloja",
    ctalink: "https://www.lodhaworldschool.com/taloja/",
  },
];

export const applytoTaloja = [
  {
    link: "#", // TODO: PDFs link to be updated - prabhash
    name: "Marathi Teacher - Grade I to III",
  },
  {
    link: "#",
    name: "English & Social Studies Teacher - Grade V to VIII",
  },
];

export const storyTaloja = [
  {
    text: "Our school represents not just another branch of the Lodha World School community, but also a new venture.",
  },
  {
    text: "In June 2019, Lodha World School set up its first SSC-affiliated campus at Taloja, with the aim of bringing education to the masses.",
  },
  {
    text: "Equipped with well-trained faculty and state-of-the-art infrastructure, our school endeavours to nurture the young minds in and around the neighbourhood, shaping them to be the future of the nation.",
  },
  {
    text: "The Taloja Community - across its learners, teachers, parents - works together to achieve the LWS motto of creating responsible and empathetic global citizens.",
  },
];

export const markersTaloja = [
  {
    id: "symbiotic-1",
  },
  {
    id: "symbiotic-2",
  },
  {
    id: "symbiotic-3",
  },
  {
    id: "symbiotic-4",
  },
  {
    id: "symbiotic-5",
  },
];

export const designTaloja = [
  {
    id: "design-1",
  },
  {
    id: "design-2",
  },
  {
    id: "design-3",
  },
  {
    id: "design-4",
  },
];

export const environmentTaloja = [
  {
    id: "environment-1",
  },
  {
    id: "environment-2",
  },
  {
    id: "environment-3",
  },
  {
    id: "environment-4",
  },
];

export const wellnessTaloja = [
  {
    id: "wellness-1",
  },
  {
    id: "wellness-2",
  },
  {
    id: "wellness-3",
  },
  {
    id: "wellness-4",
  },
];

export const infraTaloja = [
  {
    id: "infraTaloja-1",
    title: "Pre-primary Classroom",
  },
  {
    id: "infraTaloja-2",
    title: "Classroom",
  },
  {
    id: "infraTaloja-3",
    title: "Library",
  },
  {
    id: "infraTaloja-4",
    title: "Chemistry Lab",
  },
  {
    id: "infraTaloja-5",
    title: "Biology Lab",
  },
  {
    id: "infraTaloja-6",
    title: "Physics Lab",
  },
  {
    id: "infraTaloja-7",
    title: "Playground",
  },
  {
    id: "infraTaloja-8",
    title: "Computer Lab",
  },
];

export const infraDataTaloja = {
  para1:
    "The environment at the school is designed to enable students to excel and flourish - not just in academics, but in activities and events beyond the classroom, too. Lodha World School, Taloja is well-equipped with state-of-the-art infrastructure and facilities across all its learning spaces.",
};
