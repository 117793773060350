import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import {
  Grid,
  Box,
  Typography,
  List,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Contact from "../components/Contact";
import SubPagesLeftMenu from "../components/SubPagesLeftMenu";
// import BackToStart from "../components/BackToStart";
import usePathParameter from "../utils/usePathParameter";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  schoolMain,
  applytoMain,
  pageSettingsMain,
} from "../data/MainSiteData";
import {
  schoolCbgPalava,
  applytoCbgPalava,
} from "../data/CbgPalavaIndividualSiteData";
import {
  schoolLsgPalava,
  applytoLsgPalava,
} from "../data/LsgPalavaIndividualSiteData";
import { schoolTaloja, applytoTaloja } from "../data/TalojaIndividualSiteData";
import { schoolThane, applytoThane } from "../data/ThaneIndividualSiteData";
// import MinioFile from "../components/MinioFile";
import { openFileInNewTab } from "../utils/minio";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100vh",
    height: "100vw",
    position: "absolute",
    transform: "rotate(-90deg) translateX(-100vh)",
    transformOrigin: "top left",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      height: "100%",
      position: "initial",
      width: "auto",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "100vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  main: {
    width: "100vw",
    height: "100vh",
  },
  fourSchoolImg: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  rightSection: {
    [theme.breakpoints.down("md")]: {
      padding: "1rem",
    },
  },
  containerMain: {
    height: "100vh",
    marginLeft: "4rem",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },

  containerMain1: {
    height: "100vh",
    marginLeft: "4rem",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      flexDirection: "row",
    },
  },
  subText: {
    width: "120vh",
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      fontSize: "initial",
      paddingBottom: "10px",
    },
  },
  subText1: {
    width: "90vh",
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      fontSize: "initial",
      paddingBottom: "10px",
    },
  },
  links: {
    margin: 0,
    padding: 0,
    color: "#263657",
    textDecoration: "none",
    textAlign: "inherit",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  linksNotLinked: {
    margin: 0,
    padding: 0,
    color: "#263657",
    textDecoration: "none",
    pointerEvents: "none",
    textAlign: "inherit",
    "&:hover": {
      textDecoration: "none",
    },
  },
  title1: {
    fontSize: "17px",
  },
  altText: {
    overflowWrap: "anywhere",
    padding: "15px",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  openings: {
    width: "50vw",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingRight: "3rem",
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
      paddingBottom: "1rem",
    },
  },
}));

export default function Career() {
  const classes = useStyles();
  const leftMenuRef = useRef(null);
  const rightSectionRef = useRef(null);
  // const [scrollPosition, setScrollPosition] = useState(0);
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane } =
    usePathParameter();
  const [school, setSchool] = useState([]);
  const [apply, setApply] = useState([]);

  React.useEffect(() => {
    if (isThane) {
      setSchool(schoolThane);
      setApply(applytoThane);
    } else if (isCbgPalava) {
      setSchool(schoolCbgPalava);
      setApply(applytoCbgPalava);
    } else if (isLsgPalava) {
      setSchool(schoolLsgPalava);
      setApply(applytoLsgPalava);
    } else if (isTaloja) {
      setSchool(schoolTaloja);
      setApply(applytoTaloja);
    } else if (!isIndividual) {
      setSchool(schoolMain);
      setApply(applytoMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  // const scrollToBackTarget = () => {
  //   leftMenuRef.current.scrollIntoView({
  //     behavior: "smooth",
  //   });
  // };

  const handleScroll = () => {
    // const position = rightSectionRef.current?.getBoundingClientRect().x;
    // setScrollPosition(position);
  };

  if (school.length === 0) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{pageSettingsMain.staff.title}</title>
        {pageSettingsMain.staff.metaTags.map((tag) => {
          if (tag.name) {
            return (
              <meta name={tag.name} content={tag.content} key={tag.name} />
            );
          } else if (tag.property) {
            return (
              <meta
                property={tag.property}
                content={tag.content}
                key={tag.property}
              />
            );
          }
          return null;
        })}
        <link rel="canonical" href={pageSettingsMain.staff.canonical} />
      </Helmet>
      <Contact />
      {/* <MinioFile fileName="JD Biology (2).pdf" /> */}
      <Box className={classes.outerWrapper} onScroll={handleScroll}>
        <Box ref={leftMenuRef} className={classes.wrapper}>
          <Grid container className={classes.main}>
            <SubPagesLeftMenu breadCrumbTarget="Career" />
            <Grid
              ref={rightSectionRef}
              item
              xs={12}
              md={9}
              className={classes.rightSection}
            >
              <Grid container className={classes.containerMain}>
                {isIndividual ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      style={{
                        width: "100vw",
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <Typography component="p" className={classes.subText}>
                        Reach out to us with your application:
                      </Typography>
                      {school.map((school) => (
                        <Grid item xs={12} md={6}>
                          <Grid
                            container
                            style={{ textAlign: "left", marginRight: "5rem" }}
                          >
                            <Grid
                              item
                              xs={12}
                              md={8}
                              style={{ paddingTop: "1rem" }}
                            >
                              <Typography
                                class="h2"
                                color="primary"
                                variant="h1"
                              >
                                {school.name}
                              </Typography>
                              <Box
                                sx={{
                                  color: (theme) => theme.palette.black.main,
                                }}
                              >
                                <Typography className={classes.title}>
                                  <a
                                    href={`mailto:${school.email}`}
                                    alt="email id"
                                    style={{
                                      color: "#263657",
                                      textDecoration: "none",
                                    }}
                                  >
                                    <Typography style={{ fontSize: "15px" }}>
                                      <b>{school.email}</b>
                                    </Typography>
                                  </a>
                                </Typography>

                                <Typography className={classes.title}>
                                  <a
                                    href={`tel:${school.phone}`}
                                    alt="telephone"
                                    style={{
                                      color: "#263657",
                                      textDecoration: "none",
                                    }}
                                  >
                                    <Typography style={{ fontSize: "15px" }}>
                                      <b>{school.phone}</b>
                                    </Typography>
                                  </a>
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={12}
                              style={{ paddingTop: "1rem" }}
                            >
                              <hr />
                              <Typography
                                class="h2"
                                color="primary"
                                // variant="h1"
                                style={{ fontSize: "2em" }}
                              >
                                Current Openings
                              </Typography>
                              <div>
                                {apply.map((a) => (
                                  <Button
                                    onClick={() => openFileInNewTab(a.link)}
                                    alt="#"
                                    className={
                                      a.link === "#"
                                        ? classes.linksNotLinked
                                        : classes.links
                                    }
                                    style={{ display: "block" }}
                                  >
                                    <Typography>
                                      &#8226; <b>{a.name}</b>
                                    </Typography>
                                  </Button>
                                ))}
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid container className={classes.containerMain1}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          width: "58vw",
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Box className={classes.subTextMain}>
                          <Typography
                            class="h2b"
                            variant="h1"
                            style={{ lineHeight: "normal", paddingTop: "2rem" }}
                          >
                            Lodha World School - Career Opportunities & Benefits
                          </Typography>
                          <Typography
                            component="p"
                            className={classes.subText1}
                          >
                            We nurture a community of faculty who personify
                            excellence in all they do, especially creating an
                            exuberant pupil-centered environment.
                            <br />
                            <br />
                            Equipped with professional teaching credentials, our
                            staff are life-long learners who undergo additional
                            formal training and upskilling:
                            <List component="div">
                              <Box>
                                {" "}
                                &#8226; Training by Harvard Graduate School of
                                Education
                              </Box>
                              <Box>
                                {" "}
                                &#8226; Certification in Critical Thinking
                                Problem Solving by the British Council
                              </Box>
                            </List>
                            <br />
                            Join the LWS Community and nurture the Leaders of
                            Tomorrow.
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          width: "100vw",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Box className={classes.subTextMain}>
                          <Typography component="p" className={classes.subText}>
                            Along with compensation, here are some benefits you
                            will enjoy as a teacher at LWS Thane:
                            <List component="div">
                              <Box>
                                {" "}
                                &#8226; Continuous professional development by
                                Lodha Institute of Teacher Education
                              </Box>
                              <Box>
                                {" "}
                                &#8226; Child Education benefit as per the
                                school’s education policy
                              </Box>
                              <Box>
                                {" "}
                                &#8226; Mediclaim Insurance for self and family
                              </Box>
                              <Box>
                                {" "}
                                &#8226; Group Personal Accident Insurance for
                                self
                              </Box>
                            </List>
                            <br />
                            Join the LWS community and nurture the leaders of
                            tomorrow. Reach out to us with your application.
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                md={6}
                                style={{
                                  overflowWrap: "anywhere",
                                  padding: "15px 15px 15px 0",
                                }}
                              >
                                <Typography>
                                  <b>Thane:</b>
                                  <a
                                    href="mailto:careers_thane@lodhaworldschool.com"
                                    alt="email id"
                                    className={classes.links}
                                  >
                                    <Typography className={classes.title1}>
                                      careers_thane@lodhaworldschool.com
                                    </Typography>
                                  </a>
                                  <a
                                    href="tel:+91-93728-61994"
                                    alt="telephone"
                                    className={classes.links}
                                  >
                                    <Typography className={classes.title1}>
                                      +91-93728-61994
                                    </Typography>
                                  </a>
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                md={6}
                                className={classes.altText}
                              >
                                <Typography className={classes.title}>
                                  <b>Palava CBG:</b>
                                  <a
                                    href="mailto:careers_palava@lodhaworldschool.com"
                                    alt="email id"
                                    className={classes.links}
                                  >
                                    <Typography className={classes.title1}>
                                      careers_palava@lodhaworldschool.com
                                    </Typography>
                                  </a>
                                  <a
                                    href="tel:0251-669-9588"
                                    alt="telephone"
                                    className={classes.links}
                                  >
                                    <Typography className={classes.title1}>
                                      0251-669-9588
                                    </Typography>
                                  </a>
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                md={6}
                                style={{
                                  overflowWrap: "anywhere",
                                  padding: "15px 15px 15px 0",
                                }}
                              >
                                <Typography className={classes.title}>
                                  <b>Palava LSG:</b>
                                  <a
                                    href="mailto:careers_lsg@lodhaworldschool.com"
                                    alt="email id"
                                    className={classes.links}
                                  >
                                    <Typography className={classes.title1}>
                                      careers_lsg@lodhaworldschool.com
                                    </Typography>
                                  </a>
                                  <a
                                    href="tel:0251-669-6312"
                                    alt="telephone"
                                    className={classes.links}
                                  >
                                    <Typography className={classes.title1}>
                                      0251-669-6312
                                    </Typography>
                                  </a>
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                md={6}
                                className={classes.altText}
                              >
                                <Typography className={classes.title}>
                                  <b>Taloja:</b>
                                  <a
                                    href="mailto:careers_taloja@lodhaworldschool.com"
                                    alt="email id"
                                    className={classes.links}
                                  >
                                    <Typography className={classes.title1}>
                                      careers_taloja@lodhaworldschool.com
                                    </Typography>
                                  </a>
                                  <a
                                    href="tel:+91-93216-83402"
                                    alt="telephone"
                                    className={classes.links}
                                  >
                                    <Typography className={classes.title1}>
                                      +91 93216 83402
                                    </Typography>
                                  </a>
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                md={12}
                                className={classes.altText}
                                style={{ padding: "15px 0" }}
                              >
                                <Typography className={classes.title}>
                                  <b>Central Admin:</b>
                                  <a
                                    href="mailto:careers@lodhaworldschool.com"
                                    alt="email id"
                                    className={classes.links}
                                  >
                                    <Typography className={classes.title1}>
                                      careers@lodhaworldschool.com
                                    </Typography>
                                  </a>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={12} className={classes.openings}>
                        <Typography class="h2b" variant="h1">
                          Current Openings
                        </Typography>
                        <div>
                          <Accordion
                            style={{
                              background: "#263657",
                              color: "#fff",
                              marginBottom: 0,
                              marginTop: 0,
                            }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon
                                  style={{
                                    color: "#fff",
                                  }}
                                />
                              }
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                Thane
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              style={{
                                background: "#fff",
                                color: "#000",
                                maxHeight: "130px",
                                overflowY: "scroll",
                              }}
                            >
                              <Button
                                // onClick={() => openFileInNewTab("")}
                                alt="#"
                                className={classes.linksNotLinked}
                              >
                                <Typography>
                                  &#8226; <b>Maths Teacher - Middle School</b>
                                </Typography>
                              </Button>
                              <br />
                              <Button
                                onClick={() => openFileInNewTab("")}
                                alt="#"
                                className={classes.linksNotLinked}
                              >
                                <Typography>
                                  &#8226;{" "}
                                  <b>
                                    History & Geography Teacher - Middle School
                                  </b>
                                </Typography>
                              </Button>
                              <br />
                              <Button
                                // onClick={() => openFileInNewTab("")}
                                alt="#"
                                className={classes.linksNotLinked}
                              >
                                <Typography>
                                  &#8226; <b>Art & Craft Teacher</b>
                                </Typography>
                              </Button>
                              <br />
                              <Button
                                // onClick={() => openFileInNewTab("")}
                                alt="#"
                                className={classes.linksNotLinked}
                              >
                                <Typography>
                                  &#8226; <b>Front Desk Executive</b>
                                </Typography>
                              </Button>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            style={{
                              background: "#263657",
                              color: "#fff",
                              marginBottom: 0,
                              marginTop: 0,
                            }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon style={{ color: "#fff" }} />
                              }
                              aria-controls="panel2bh-content"
                              id="panel2bh-header"
                            >
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                CBG Palava
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              style={{
                                background: "#fff",
                                color: "#000",
                                maxHeight: "130px",
                                overflowY: "scroll",
                              }}
                            >
                              <Button
                                // onClick={() => openFileInNewTab("")}
                                alt="#"
                                className={classes.linksNotLinked}
                              >
                                <Typography>
                                  &#8226;{" "}
                                  <b>
                                    English Teacher - Primary & Secondary | M.A.
                                    (English), B.A (English) & B.Ed
                                  </b>
                                </Typography>
                              </Button>
                              <br />
                              <Button
                                // onClick={() => openFileInNewTab("")}
                                alt="#"
                                className={classes.linksNotLinked}
                              >
                                <Typography>
                                  &#8226;{" "}
                                  <b>
                                    English & SS Teacher - Primary | M.A./B.A.
                                    (History) & B.Ed
                                  </b>
                                </Typography>
                              </Button>
                              <br />
                              <Button
                                // onClick={() => openFileInNewTab("")}
                                alt="#"
                                className={classes.linksNotLinked}
                              >
                                <Typography>
                                  &#8226;{" "}
                                  <b>
                                    Mother Teacher - Maths & Science |
                                    B.A/B.Com, B.Ed
                                  </b>
                                </Typography>
                              </Button>
                              <br />
                              <Button
                                // onClick={() => openFileInNewTab("")}
                                alt="#"
                                className={classes.linksNotLinked}
                              >
                                <Typography>
                                  &#8226;{" "}
                                  <b>
                                    Science Teacher - Primary | B.Sc /M.Sc ,
                                    B.Ed
                                  </b>
                                </Typography>
                              </Button>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            style={{
                              background: "#263657",
                              color: "#fff",
                              marginBottom: 0,
                              marginTop: 0,
                            }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon style={{ color: "#fff" }} />
                              }
                              aria-controls="panel3bh-content"
                              id="panel3bh-header"
                            >
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                LSG Palava
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              style={{
                                background: "#fff",
                                color: "#000",
                                maxHeight: "130px",
                                overflowY: "scroll",
                              }}
                            >
                              <Button
                                onClick={() =>
                                  openFileInNewTab("JD for Counselor.pdf")
                                }
                                alt="#"
                                className={classes.links}
                              >
                                <Typography>
                                  &#8226; <b>School Counsellor</b>
                                </Typography>
                              </Button>
                              <br />
                              <Button
                                onClick={() =>
                                  openFileInNewTab(
                                    "JD for Physical Education Teacher Secondary+Senior Secondary.pdf"
                                  )
                                }
                                alt="#"
                                className={classes.links}
                              >
                                <Typography>
                                  &#8226; <b>Physical Education Teacher</b>
                                </Typography>
                              </Button>
                              <br />
                              <Button
                                onClick={() =>
                                  openFileInNewTab(
                                    "JD for Primary English Teacher.pdf"
                                  )
                                }
                                alt="#"
                                className={classes.links}
                              >
                                <Typography>
                                  &#8226; <b>English Teacher - Primary</b>
                                </Typography>
                              </Button>
                              <br />
                              <Button
                                onClick={() =>
                                  openFileInNewTab(
                                    "JD for Lab Assistant (Physics+Chemistry+Biology) (1).pdf"
                                  )
                                }
                                alt="#"
                                className={classes.links}
                              >
                                <Typography>
                                  &#8226; <b>Lab Assistant</b>
                                </Typography>
                              </Button>
                              <br />
                              <Button
                                onClick={() =>
                                  openFileInNewTab(
                                    "JD for Chemistry Teacher (Secondary+Sr.Secondary) (1).pdf"
                                  )
                                }
                                alt="#"
                                className={classes.links}
                              >
                                <Typography>
                                  &#8226; <b>Chemistry Teacher - Secondary</b>
                                </Typography>
                              </Button>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            style={{
                              background: "#263657",
                              color: "#fff",
                              marginBottom: 0,
                              marginTop: 0,
                            }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon style={{ color: "#fff" }} />
                              }
                              aria-controls="panel4bh-content"
                              id="panel4bh-header"
                            >
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                Taloja
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              style={{
                                background: "#fff",
                                color: "#000",
                                maxHeight: "130px",
                                overflowY: "scroll",
                              }}
                            >
                              <Button
                                onClick={() =>
                                  openFileInNewTab("JD of Marathi Teacher.pdf")
                                }
                                alt="#"
                                className={classes.links}
                              >
                                <Typography>
                                  &#8226;{" "}
                                  <b>Marathi Teacher - Grade I to III</b>
                                </Typography>
                              </Button>
                              <br />
                              <Button
                                onClick={() =>
                                  openFileInNewTab(
                                    "JD of English SS Teacher.pdf"
                                  )
                                }
                                alt="#"
                                className={classes.links}
                              >
                                <Typography>
                                  &#8226;{" "}
                                  <b>
                                    English & Social Studies Teacher - Grade V
                                    to VIII
                                  </b>
                                </Typography>
                              </Button>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            style={{
                              background: "#263657",
                              color: "#fff",
                              marginBottom: 0,
                              marginTop: 0,
                            }}
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon style={{ color: "#fff" }} />
                              }
                              aria-controls="panel5bh-content"
                              id="panel5bh-header"
                            >
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                Central Admin Team
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              style={{
                                background: "#fff",
                                color: "#000",
                                maxHeight: "130px",
                                overflowY: "scroll",
                              }}
                            >
                              <Typography>No openings yet</Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
