import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Grid, Box, Typography, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Contact from "../components/Contact";
import SubPagesLeftMenu from "../components/SubPagesLeftMenu";
import BackToStart from "../components/BackToStart";
import usePathParameter from "../utils/usePathParameter";
import { resultsThane } from "../data/ThaneIndividualSiteData";
import { resultsMain, pageSettingsMain } from "../data/MainSiteData";
import { resultsCbgPalava } from "../data/CbgPalavaIndividualSiteData";
import { resultsLsgPalava } from "../data/LsgPalavaIndividualSiteData";
import { resultsTaloja } from "../data/TalojaIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    width: "100vh",
    height: "100vw",
    position: "absolute",
    transform: "rotate(-90deg) translateX(-100vh)",
    transformOrigin: "top left",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      height: "100%",
      position: "initial",
      width: "auto",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "100vw",
    transform: "rotate(90deg) translateY(-100vh)",
    transformOrigin: "top left",
    [theme.breakpoints.down("md")]: {
      transform: "none",
      transformOrigin: "unset",
      flexDirection: "column",
      width: "100vw",
    },
  },
  main: {
    width: "100vw",
    height: "100vh",
  },
  nextBtn: {
    position: "relative",
    bottom: "6rem",
    left: "5rem",
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    borderRadius: "50%",
    width: "90px",
    height: "90px",
    fontSize: "13px",
    border: "1px solid #263657",
    backgroundColor: "#263657",
    color: "#fff",
    "&:hover": {
      background: "#263657",
      border: "1px solid #263657",
      color: "#fff",
    },
  },
  mainContainer: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "9% 0",
    [theme.breakpoints.down("md")]: {
      padding: 0,
      flexDirection: "row",
    },
  },
  titleMain: {
    margin: "0 10rem",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      padding: "1rem",
    },
  },
  titleContainer: {
    padding: "2rem 0",
    width: "40vw",
    [theme.breakpoints.down("md")]: {
      padding: 0,
      width: "100%",
    },
  },
  subText: {
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      fontSize: "initial",
    },
  },
  logoMain: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-evenly",
      flexWrap: "wrap",
    },
  },
  h1Text: {
    fontFamily: "Lato",
    fontWeight: "bold",
    color: "#898EAC",
    fontSize: "55px",
    marginBottom: "10px",
    marginTop: "0px",
    transitionDuration: "800ms",
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
  },
  h1Main: {
    marginLeft: "3rem",
    width: "70vw",
    display: "flex",
    alignItems: "flex-start",
    height: "100vh",
    flexDirection: "column",
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      width: "100vw",
      height: "auto",
      padding: "1rem",
    },
  },
}));

export default function Results() {
  const classes = useStyles();
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane } =
    usePathParameter();
  const leftMenuRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [results, setResults] = useState([]);
  const rightSectionRef = useRef(null);

  useEffect(() => {
    if (isThane) {
      setResults(resultsThane);
    } else if (isCbgPalava) {
      setResults(resultsCbgPalava);
    } else if (isLsgPalava) {
      setResults(resultsLsgPalava);
    } else if (isTaloja) {
      setResults(resultsTaloja);
    } else if (!isIndividual) {
      setResults(resultsMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  const [scrollPosition, setScrollPosition] = useState(0);

  const scrollToBackTarget = () => {
    leftMenuRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const position = rightSectionRef.current?.getBoundingClientRect().x;
    setScrollPosition(position);
  };

  const Logos = [
    {
      logo: "/assets/logo1.png",
      link: "https://msu.edu/",
      width: "120px",
      alt: "Alumni's Destination - Michigan State University, LWS",
      imgTitle: "Alumni's Destination at Michigan State University, LWS",
    },
    {
      logo: "/assets/logo4.png",
      link: "https://xaviers.ac/",
      width: "120px",
      alt: "Alumni's Destination - IHS, LWS",
      imgTitle: "Alumni's Destination at IHS, LWS",
    },
    {
      logo: "/assets/logo5.png",
      link: "https://www.uwl.ac.uk/",
      width: "120px",
      alt: "Alumni's Destination - University of West London, LWS",
      imgTitle: "Alumni's Destination at University of West London, LWS",
    },
    {
      logo: "/assets/logo2.png",
      link: "https://www.ualberta.ca/index.html",
      width: "150px",
      alt: "Alumni's Destination - University of Alberta, LWS",
      imgTitle: "Alumni's Destination at University of Alberta, LWS",
    },
    {
      logo: "/assets/logo3.png",
      link: "https://www.iit.edu/",
      width: "180px",
      alt: "Alumni's Destination - Illionis Institute of technology, LWS",
      imgTitle: "Alumni's Destination at Illionis Institute of technology, LWS",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => setCurrentSlide((prv) => prv + 1), 3500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageSettingsMain.results.title}</title>
        {pageSettingsMain.results.metaTags.map((tag) => {
          if (tag.name) {
            return (
              <meta name={tag.name} content={tag.content} key={tag.name} />
            );
          } else if (tag.property) {
            return (
              <meta
                property={tag.property}
                content={tag.content}
                key={tag.property}
              />
            );
          }
          return null;
        })}
        <link rel="canonical" href={pageSettingsMain.results.canonical} />
      </Helmet>
      <Contact />
      <Box className={classes.outerWrapper} onScroll={handleScroll}>
        <Box ref={leftMenuRef} className={classes.wrapper}>
          <Grid container className={classes.main}>
            <SubPagesLeftMenu breadCrumbTarget="Results & Placements" />
            <Grid
              ref={rightSectionRef}
              item
              xs={12}
              md={7}
              className={classes.rightSection}
            >
              <Grid container className={classes.mainContainer}>
                <Grid item xs={12} className={classes.titleMain}>
                  <Typography
                    class="h2b"
                    variant="h1"
                    style={{ lineHeight: "normal" }}
                  >
                    Lodha World School - 2023 Results
                  </Typography>

                  <Box className={classes.titleContainer}>
                    <Typography component="p" className={classes.subText}>
                      The Class of 2023 Results stand testament to our
                      relentless drive towards pursuing our vision of academic
                      excellence. While our erudite leaders have performed
                      exceptionally, it was the joint endeavours and efforts of
                      our staff, parents, and students that brought about this
                      spectacular outcome.
                      <br />
                      <br />
                      Through collaboration, we achieve excellence.
                    </Typography>
                  </Box>
                </Grid>

                {/* sports */}
                <>
                  <Box className={classes.h1Main}>
                    <Typography className={classes.h1Text} variant="h2">
                      {results[currentSlide % results.length]?.title}
                    </Typography>
                    <Grid container className={classes.root}>
                      <Grid item xs={12}>
                        <div class="slider-wrap">
                          <div
                            className="single-slideRes"
                            style={{
                              transitionDuration: "800ms",
                              border: "1px solid #263657",
                            }}
                            id={results[currentSlide % results.length]?.id}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </>

                <Grid item xs={12} className={classes.titleMain}>
                  <Typography class="h2b" variant="h2">
                    University Placements
                  </Typography>

                  <Box className={classes.titleContainer}>
                    <Typography component="p" className={classes.subText}>
                      The avenues that an LWS education opens up for the future
                      are endless.
                      <br />
                      <br />
                      Where our students go and what they do or become is one of
                      the strongest measures of our success as a school.
                      <br />
                      <br />
                      Some of our alumni’s destinations are below.
                    </Typography>
                    <Box className={classes.logoMain}>
                      {Logos.map((e) => (
                        <Link href={e.link} alt="" target="_blank">
                          <img
                            src={e.logo}
                            alt={e.alt}
                            title={e.imgTitle}
                            width={e.width}
                            class="responsiveImg"
                          />
                        </Link>
                      ))}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} style={{ padding: "0.5rem" }}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {scrollPosition < 0 ? (
        <BackToStart goToTarget={scrollToBackTarget} />
      ) : null}
    </>
  );
}
